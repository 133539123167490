/* main.css */
.project-container {
    display: grid;
    border: 3px solid var(--clr-primary);
    box-shadow: var(--shadow);
    border-radius: 16px;
    padding: 16px;
    gap: 8px;
    background-color: transparent;
    transition: all 0.5s ease 0s;
}
.project-container:hover {
  /* transform: scale(1.04); */
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.project-container img {
    width: 100%;
    height: 200px;
}

.image-wrapper {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  
.image-wrapper img {
    display: block;
    width: 100%;
    height: auto;
  }
.image-wrapper:hover .overlay {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  
.image-wrapper:hover .view-button {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  
.image-wrapper:hover img {
    border: 2px solid #fff;
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
  }
.project-img {
    width: -webkit-fill-available;
  }
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
.view-btn {
    color: var(--btn-primary-color);
    border: 1px solid var(--main-theme-color);
    padding: 14px 22px;
    align-items: center;
    border-radius: 8px;
    background-color: var(--white-color);
    font-family: var(--main-font-family);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.03em;
    text-decoration: none;
    transition: color 100ms, background-color 100ms;
    -webkit-transition: color 100ms, background-color 100ms;
    -moz-transition: color 100ms, background-color 100ms;
    -o-transition: color 100ms, background-color 100ms;
  }
  
.view-btn:hover {
    color: var(--white-color);
    background-color: var(--btn-primary-color);
  }
.view-btn{
    color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
    border-radius: 5px;
    height: auto;
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;
  }
.view-btn:hover{
    color: var(--clr-bg);
    border: 2px solid var(--clr-primary);
    background-color: var(--clr-primary);
  }
.view-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
    background-color: transparent;
    transition: opacity 0.5s ease-in-out;
  }
  
.view-button:hover {
  color: var(--white-color);
  border: 2px solid var(--clr-primary);
    border: 1px solid var(--btn-primary-color);
  }
.about-project {
    flex-direction: column;
    gap: 12px;
  }
.project-title h2 {
    font-size: 33px;
    font-weight: 700;
  }

.project-title,
.client-name {
    color: var(--sub-title-color);
  }
.project-info ul {
    column-gap: 12px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
.flex {
    display: flex;
}

.client-name {
    text-transform: uppercase;
  }
  
.client-name,
.role,
.year {
    font-weight: 600;
    font-size: 13px;
  }
.counter {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    background-color: var(--dot-dolor);
  }
.role,
.year {
    color: var(--neutral-color);
  }
.project-description {
    color: var(--sub-title-color);
    font-size: 15px;
    font-weight: 400;
  }
  
.project-description-container {
    padding: 12px 0;
  }
.tags {
    margin-bottom: 12px;
  }
  
.tags ul {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  
.tags ul li {
    background-color: var(--tags-bg-color);
    margin: 4px;
    padding: 4px 12px;
    border-radius: 8px;
    color: var(--main-theme-color);
    font-size: 12px;
    font-weight: 500;
  }
.btn-container {
    height: 72px;
  }
  /* desktop.css */
.project-container {
    grid-template-columns: 1fr 1fr;
    border-radius: 24px;
    gap: 24px;
  }

.project-container:nth-child(odd) .project-img-container {
    order: 1;
  }

.project-img-container {
    padding: 64px 8px;
  }

.project-img-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    overflow: hidden;
  }
.about-project {
    padding: 24px;
  }
.project-description-container {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    padding: 39px 24px;
  }
.tags ul {
    margin: 0;
  }
img{
  border: 3px solid var(--clr-primary);
  border-radius: 20px;
}
.source-container {
  display: flex;
  align-items: center;
}

.source-container .github-logo {
  margin-left: 5px; /* Adjust the spacing between the text and the logo */
  margin-top: 3px;
  display: inline-block; /* Ensures the logo is treated as an inline element */
  vertical-align: middle; /* Aligns the logo vertically in relation to the text */
}
.flex-column{
  display: flex;
  flex-direction: row;
}
.new-tab-btn {
  margin-left: 10px;
  size: 10px;
}
/* mobile.css */
@media (max-width: 767px) {
    .project-container {
        display: flex;
        flex-direction: column-reverse;
    }
    
    .project-container img {
      width: 100%;
      height: 90%;
    }
    .project-container:nth-child(odd) .project-img-container {
        order: 1;
      }
    .project-container:nth-child(even) .project-img-container {
        order: 1;
      }
      .project-img-container {
        padding: 16px 8px;
      }
      .about-project {
        padding: 16px;
      }
  }